/* --------------- variables --------------------- */
/* Break Point */
/* Color */
.bold {
  font-weight: 700;
}

#archiveContet h2.title {
  font-weight: 700;
  line-height: 1.5;
  position: relative;
}
#archiveContet h2.title:before {
  content: "";
  position: absolute;
  background-color: #000;
  left: 0;
  height: 2px;
}

#coursePage h1,
#coursePage h2,
#coursePage h3,
#coursePage h4,
#coursePage h5 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
}
#coursePage h2.title {
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  font-size: 18px;
  padding-block: 10px;
  text-align: center;
}
#coursePage h2.title:before, #coursePage h2.title:after {
  content: "";
  position: absolute;
  background-color: #000;
  left: 0;
  width: 100%;
  height: 1px;
}
#coursePage h2.title:before {
  top: 0;
}
#coursePage h2.title:after {
  bottom: 0;
}
#coursePage h2.title.pro {
  color: #AE6741;
}
#coursePage h2.title.pro:before, #coursePage h2.title.pro:after {
  background-color: #AE6741;
}
#coursePage .accordionWrap {
  display: flex;
}
#coursePage .accordionWrap dl.accordionBtn dt {
  text-align: left;
  position: relative;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
  background-color: #000;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  min-height: 70px;
  padding-block: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0em;
}
#coursePage .accordionWrap dl.accordionBtn dt:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 20px;
  height: 20px;
}
#coursePage .accordionWrap dl.accordionBtn dt.open {
  border-radius: 5px 5px 0 0;
}
#coursePage .accordionWrap dl.accordionBtn dt.open:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}
#coursePage .accordionWrap dl.accordionBtn dd {
  display: none;
  position: relative;
  background: #F8F8F8;
  color: #000;
  border-radius: 0px 0px 5px 5px;
  padding: 40px;
}
#coursePage .accordionWrap dl.accordionBtn dd .img img {
  border-radius: 10px;
}
#coursePage .accordionWrap dl.accordionBtn.professional dt {
  text-align: left;
}
#coursePage .accordionWrap dl.accordionBtn.professional dt .tag {
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}
#coursePage .accordionWrap dl.accordionBtn.professional dt .dt {
  margin-top: 0px;
}
#coursePage .proDiscription * + p {
  margin-top: 0px;
}
#coursePage .grayBox {
  position: relative;
  background: #F8F8F8;
  color: #000;
}
#coursePage .grayBox .img img {
  border-radius: 10px;
}
#coursePage .grayBox .text p.title {
  font-size: 20px;
}
#coursePage .instructorList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
#coursePage .instructorList li button {
  border: 0px;
  background-color: #fff;
  cursor: pointer;
}
#coursePage .instructorList li picture {
  width: 100%;
  position: relative;
  display: block;
  aspect-ratio: 1/1;
}
#coursePage .instructorList li picture::before {
  content: "";
  background: linear-gradient(to right bottom, #000 50%, transparent 50%);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px 0 0 0;
}
#coursePage .instructorList li picture:after {
  content: "";
  position: absolute;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}
#coursePage .instructorList li picture.white:after {
  background: url(../images/common/icon_open_w.svg) no-repeat 0 0/contain;
}
#coursePage .instructorList li picture.black:after {
  background: url(../images/common/icon_open_b.svg) no-repeat 0 0/contain;
}
#coursePage .instructorList li picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
#coursePage .instructorList li .textWrap {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  row-gap: 5px;
  padding-inline: 5px;
}
#coursePage .instructorList li .textWrap span {
  text-align: center;
  line-height: 1.3;
}
#coursePage .instructorList li .textWrap span.name {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
#coursePage .instructorList li .textWrap span.name .small {
  font-size: 16px;
  line-height: 30px;
  margin-left: 4px;
}
#coursePage .instructorList #modal01,
#coursePage .instructorList #modal02,
#coursePage .instructorList #modal03,
#coursePage .instructorList #modal04 {
  position: fixed;
  left: 50%;
  display: none;
  transition: all 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 11;
}
#coursePage .instructorList #modal01.isShow,
#coursePage .instructorList #modal02.isShow,
#coursePage .instructorList #modal03.isShow,
#coursePage .instructorList #modal04.isShow {
  display: block;
}
#coursePage .instructorList #modal01 .modal_inner,
#coursePage .instructorList #modal02 .modal_inner,
#coursePage .instructorList #modal03 .modal_inner,
#coursePage .instructorList #modal04 .modal_inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#coursePage .instructorList #modal01 .modal_inner .modal_contents,
#coursePage .instructorList #modal02 .modal_inner .modal_contents,
#coursePage .instructorList #modal03 .modal_inner .modal_contents,
#coursePage .instructorList #modal04 .modal_inner .modal_contents {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
#coursePage .instructorList #modal01 .modal_inner .modal_contents::before,
#coursePage .instructorList #modal02 .modal_inner .modal_contents::before,
#coursePage .instructorList #modal03 .modal_inner .modal_contents::before,
#coursePage .instructorList #modal04 .modal_inner .modal_contents::before {
  content: "";
  background: linear-gradient(to right bottom, #000 50%, transparent 50%);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px 0 0 0;
  width: 24px;
  height: 24px;
}
#coursePage .instructorList #modal01 .modal_inner .modal_contents .textWrap span,
#coursePage .instructorList #modal02 .modal_inner .modal_contents .textWrap span,
#coursePage .instructorList #modal03 .modal_inner .modal_contents .textWrap span,
#coursePage .instructorList #modal04 .modal_inner .modal_contents .textWrap span {
  text-align: left;
}
#coursePage .instructorList #modal01 .modal_inner .modal_contents .messages,
#coursePage .instructorList #modal02 .modal_inner .modal_contents .messages,
#coursePage .instructorList #modal03 .modal_inner .modal_contents .messages,
#coursePage .instructorList #modal04 .modal_inner .modal_contents .messages {
  letter-spacing: 0em;
  margin-top: 40px;
}
#coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss01,
#coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss02,
#coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss03,
#coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss04,
#coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss01,
#coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss02,
#coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss03,
#coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss04,
#coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss01,
#coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss02,
#coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss03,
#coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss04,
#coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss01,
#coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss02,
#coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss03,
#coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss04 {
  content: "";
  margin-top: 0;
  position: absolute;
  display: block;
  cursor: pointer;
  background: url(../images/common/icon_dismiss.svg) no-repeat 0 0/contain;
}
#coursePage .instructorList .modal_backgronud01,
#coursePage .instructorList .modal_backgronud02,
#coursePage .instructorList .modal_backgronud03,
#coursePage .instructorList .modal_backgronud04 {
  display: none;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  background: rgba(122, 122, 122, 0.5);
  z-index: 10;
}
#coursePage .instructorList .modal_backgronud01.isShow,
#coursePage .instructorList .modal_backgronud02.isShow,
#coursePage .instructorList .modal_backgronud03.isShow,
#coursePage .instructorList .modal_backgronud04.isShow {
  display: block;
}
#coursePage .corpoPrice {
  text-align: center;
}
#coursePage .corpoPrice h3.title {
  color: #fff;
  background-color: #000;
  display: inline-block;
  font-size: 18px;
}
#coursePage .corpoPrice .price {
  letter-spacing: 0em;
  font-weight: 700;
}
#coursePage .corpoPrice .price .size36 {
  font-family: "Inter", sans-serif;
}
#coursePage .corpoPrice .price .dBlock {
  font-weight: 400;
  display: block;
}

#news .wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 10px;
}
#news a.page,
#news .current {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
#news a.page {
  background-color: #f5f5f5;
}
#news .current {
  background-color: #000;
  color: #fff;
}
#news .previouspostslink,
#news .nextpostslink {
  display: block;
  font-size: 0px;
  width: 20px;
  height: 20px;
  position: relative;
}
#news .previouspostslink:before,
#news .nextpostslink:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  width: 20px;
  height: 20px;
  display: block;
}
#news .previouspostslink:before {
  transform: rotate(-180deg);
}

.course .list li a .imgWrap.vocal {
  background-image: url(../images/home/course01_thum_sp.png) !important;
  background-size: cover;
}
.course .list li a .imgWrap.guiter {
  background-image: url(../images/home/course02_thum_sp.png) !important;
  background-size: cover;
}
.course .list li a .imgWrap.kids {
  background-image: url(../images/home/course03_thum_sp.png) !important;
  background-size: cover;
}
.course .list li a .imgWrap.lgbtq {
  background-image: url(../images/home/course04_thum_sp.png) !important;
  background-size: cover;
}
.prevNextLink {
  display: flex;
  justify-content: center;
}
.prevNextLink .prev {
  position: relative;
  padding-left: 40px;
}
.prevNextLink .prev a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  display: block;
  transform: translate(0, -50%) rotate(-180deg);
  left: 0;
  top: 50%;
}
.prevNextLink .next {
  position: relative;
  padding-right: 40px;
}
.prevNextLink .next a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  display: block;
  transform: translate(0, -50%);
  right: 0;
  top: 50%;
}

.en {
  font-family: "Inter", sans-serif !important;
}

.breadcrumbArea .breadcrumb > li {
  position: relative;
}
.breadcrumbArea .breadcrumb > li + li:before {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  border-top: 1px solid #747474;
  border-right: 1px solid #747474;
  transform: rotate(45deg);
  box-sizing: border-box;
}
.breadcrumbArea .breadcrumb > li a {
  color: #747474 !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}
.breadcrumbArea .breadcrumb > li > span {
  color: #747474;
}
.pageHeader .inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
  position: relative;
}
.pageHeader .pageTitleSub {
  font-family: "Inter", sans-serif;
  text-transform: capitalize;
  color: #747474;
  font-weight: 600;
  letter-spacing: 0.1em;
}
.pageHeader .pageTitle {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.4;
}
.pageHeader .pageTitle.thanks {
  letter-spacing: 0.02em;
  text-align: center;
}
.pageHeader .pageImg {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.pageHeader .pageImg img {
  object-fit: cover;
  object-position: 50% 50% !important;
  width: 100% !important;
  height: 100% !important;
}
.pageHeader.courseTitle .pageTitle {
  position: relative;
  font-size: 32px;
}
.pageHeader.courseTitle .pageTitle:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
}
.pageHeader.courseTitle .pageTitle.vocal:before {
  background: #FF80D9;
}
.pageHeader.courseTitle .pageTitle.guiter:before {
  background: #FFB800;
}
.pageHeader.courseTitle .pageTitle.kids:before {
  background: #55E19E;
}
.pageHeader.courseTitle .pageTitle.corporations:before {
  background: #000;
}

.archivePageHeader .inner {
  padding: 0;
  margin: 0 auto;
  position: relative;
}
.archivePageHeader .category {
  width: 96px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
}
.archivePageHeader .category.pink {
  color: #FF6E6E;
  border: 1px solid #FF6E6E;
}
.archivePageHeader .category.yellow {
  color: #FFB800;
  border: 1px solid #FFB800;
}
.archivePageHeader .category.green {
  color: #55E19E;
  border: 1px solid #55E19E;
}
.archivePageHeader .date {
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.archivePageHeader.blog .inner {
  position: relative;
}
.archivePageHeader.blog .inner:before {
  content: "";
  position: absolute;
  background-color: #dadada;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}
.archivePageHeader.blog .category {
  display: none;
}

main#main,
main#course,
main#archivePage {
  flex: inherit;
}
main#main img,
main#main source,
main#course img,
main#course source,
main#archivePage img,
main#archivePage source {
  width: auto;
}
main#main picture,
main#course picture,
main#archivePage picture {
  display: inline;
}
main#main h2,
main#main h3,
main#main h4,
main#main h5,
main#course h2,
main#course h3,
main#course h4,
main#course h5,
main#archivePage h2,
main#archivePage h3,
main#archivePage h4,
main#archivePage h5 {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}
main#main .archivePageHeader h1,
main#course .archivePageHeader h1,
main#archivePage .archivePageHeader h1 {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}
main#main p.title,
main#course p.title,
main#archivePage p.title {
  font-weight: 700;
}
main#main p.description,
main#course p.description,
main#archivePage p.description {
  letter-spacing: 0em;
}
main#main a,
main#course a,
main#archivePage a {
  color: #000;
  text-decoration: underline;
}
main#main > .section:nth-of-type(1),
main#course > .section:nth-of-type(1),
main#archivePage > .section:nth-of-type(1) {
  padding-top: 0 !important;
}
main#main .content > .section:nth-of-type(1),
main#course .content > .section:nth-of-type(1),
main#archivePage .content > .section:nth-of-type(1) {
  padding-top: 0 !important;
}
main#main .section[data-background=bgGrad],
main#course .section[data-background=bgGrad],
main#archivePage .section[data-background=bgGrad] {
  background: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
}
main#main [class^=listCol] img,
main#course [class^=listCol] img,
main#archivePage [class^=listCol] img {
  border-radius: 10px;
}
main#main .listCol2.instoructor > .listItem.img img,
main#course .listCol2.instoructor > .listItem.img img,
main#archivePage .listCol2.instoructor > .listItem.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 152/176;
}
main#main .listCol2.instoructor > .listItem.text,
main#course .listCol2.instoructor > .listItem.text,
main#archivePage .listCol2.instoructor > .listItem.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main#main .listCol2.instoructor > .listItem.text .instoructorName,
main#course .listCol2.instoructor > .listItem.text .instoructorName,
main#archivePage .listCol2.instoructor > .listItem.text .instoructorName {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.02em;
}
main#main .listCol2.instoructor > .listItem.text .postName,
main#course .listCol2.instoructor > .listItem.text .postName,
main#archivePage .listCol2.instoructor > .listItem.text .postName {
  font-size: 14px;
  letter-spacing: 0em;
}
main#main .itemCenter *,
main#course .itemCenter *,
main#archivePage .itemCenter * {
  margin-inline: auto;
  display: block;
}
main#main .itemCenter img,
main#course .itemCenter img,
main#archivePage .itemCenter img {
  border-radius: 10px;
}
main#main .bdBox,
main#course .bdBox,
main#archivePage .bdBox {
  border: 1px solid #dadada;
  border-radius: 10px;
}
main#main .btnWrap,
main#course .btnWrap,
main#archivePage .btnWrap {
  border-radius: 5px;
}
main#main .btnWrap.apply,
main#course .btnWrap.apply,
main#archivePage .btnWrap.apply {
  background-color: #fff;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
}
main#main .btnWrap a,
main#course .btnWrap a,
main#archivePage .btnWrap a {
  display: block;
  color: #fff;
  font-weight: 700;
  background: #000;
  height: 70px;
  line-height: 70px;
  border-radius: 5px;
  padding-inline: 35px;
  position: relative;
  text-align: center;
  border: 1px solid #000;
  text-decoration: none;
}
main#main .btnWrap a:before,
main#course .btnWrap a:before,
main#archivePage .btnWrap a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
  top: 50%;
  right: 25px;
  transform: translate(0, -50%);
}
main#main .btnWrap a.gradBg,
main#course .btnWrap a.gradBg,
main#archivePage .btnWrap a.gradBg {
  position: relative;
  font-size: 16px;
  background: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 5px;
  border: 1px solid #fff;
}
main#main .btnWrap a.gradBg:before,
main#course .btnWrap a.gradBg:before,
main#archivePage .btnWrap a.gradBg:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_grad.svg) no-repeat 0 0/contain;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  #archiveContet {
    padding-block: 40px 86px;
  }
  #archiveContet.blog {
    padding-top: 30px;
  }
  #archiveContet * + h2.title {
    margin-top: 40px;
  }
  #archiveContet h2.title {
    font-size: 16px;
    padding-left: 34px;
  }
  #archiveContet h2.title:before {
    width: 20px;
    top: 11px;
  }
  #coursePage * + h2.title {
    margin-top: 40px;
  }
  #coursePage h2.title.pro:before, #coursePage h2.title.pro:after {
    background-color: #AE6741;
  }
  #coursePage .accordionWrap {
    margin-top: 50px;
    flex-direction: column;
    row-gap: 20px;
  }
  #coursePage .accordionWrap dl.accordionBtn dt {
    padding-inline: 50px;
  }
  #coursePage .accordionWrap dl.accordionBtn dt:before {
    right: 8px;
  }
  #coursePage .accordionWrap dl.accordionBtn dd .text {
    margin-top: 20px;
  }
  #coursePage .accordionWrap dl.accordionBtn.professional dt {
    padding-left: 12px;
  }
  #coursePage .accordionWrap dl.accordionBtn.professional dt .tag {
    width: 83px;
    line-height: 1.33;
  }
  #coursePage .accordionWrap dl.accordionBtn.professional dt .dt {
    width: calc(100% - 83px);
    padding-left: 16px;
  }
  #coursePage .proDiscription {
    margin-top: 20px;
    line-height: 2;
  }
  #coursePage * + .grayBox {
    margin-top: 50px;
  }
  #coursePage .grayBox {
    padding: 29px;
    border-radius: 5px;
  }
  #coursePage .grayBox .text {
    margin-top: 20px;
  }
  #coursePage .instructorList {
    gap: 15px;
    row-gap: 40px;
    margin-top: 40px;
  }
  #coursePage .instructorList li {
    width: calc((100% - 15px) / 2);
  }
  #coursePage .instructorList li picture::before {
    width: 15px;
    height: 15px;
  }
  #coursePage .instructorList li picture:after {
    right: 10px;
    bottom: 10px;
    width: 20px;
    height: 20px;
  }
  #coursePage .instructorList li .textWrap span {
    font-size: 12px;
  }
  #coursePage .instructorList #modal01,
  #coursePage .instructorList #modal02,
  #coursePage .instructorList #modal03,
  #coursePage .instructorList #modal04 {
    top: 50%;
    width: 100%;
    max-width: 440px;
  }
  #coursePage .instructorList #modal01 .modal_inner,
  #coursePage .instructorList #modal02 .modal_inner,
  #coursePage .instructorList #modal03 .modal_inner,
  #coursePage .instructorList #modal04 .modal_inner {
    padding-inline: 16px;
  }
  #coursePage .instructorList #modal01 .modal_inner .modal_contents,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents {
    padding-block: 30px;
    padding-inline: 30px;
  }
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss04,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss04,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss04,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss04 {
    top: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
  }
  #coursePage .corpoPrice {
    margin-top: 30px;
  }
  #coursePage .corpoPrice h3.title {
    padding-block: 5px;
    min-width: 200px;
  }
  #coursePage .corpoPrice .price {
    line-height: 36px;
    margin-top: 10px;
  }
  #coursePage .corpoPrice .price .size18 {
    font-size: 18px;
  }
  #coursePage .corpoPrice .price .size36 {
    font-size: 36px;
    padding-left: 5px;
  }
  #coursePage .corpoPrice .price .size16 {
    font-size: 16px;
  }
  .course .btn2col a.btnBlack + .btnBlack {
    margin-top: 30px !important;
  }
  .prevNextLink {
    gap: 40px;
    margin-top: 40px;
  }
  .prevNextLink .prev {
    padding-left: 25px;
  }
  .prevNextLink .prev a:before {
    width: 20px;
    height: 20px;
  }
  .prevNextLink .next {
    padding-right: 25px;
  }
  .prevNextLink .next a:before {
    width: 20px;
    height: 20px;
  }
  .breadcrumbArea {
    width: calc(100% - 50px);
    margin: 0 auto;
  }
  .breadcrumbArea .breadcrumb {
    margin-top: 15px;
  }
  .breadcrumbArea .breadcrumb > li {
    display: inline-block;
    margin: 1px 0;
    font-size: 12px;
  }
  .breadcrumbArea .breadcrumb > li:not(:last-child) {
    margin-right: 8px;
  }
  .breadcrumbArea .breadcrumb > li + li {
    padding-left: 16px;
  }
  .breadcrumbArea .breadcrumb > li + li:before {
    width: 5px;
    height: 5px;
    left: 0px;
    top: 8px;
  }
  .breadcrumbArea .breadcrumb > li a {
    display: inline;
  }
  .breadcrumbArea + .section {
    padding-top: 40px;
  }
  .pageHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 30px 40px;
  }
  .pageHeader .inner {
    width: calc(100% - 60px);
  }
  .pageHeader .pageTitleSub {
    font-size: 16px;
  }
  .pageHeader .pageTitle {
    font-size: 40px;
  }
  .pageHeader .pageTitle.thanks {
    font-size: 20px;
  }
  .pageHeader .pageImg {
    height: 200px;
  }
  .pageHeader.courseTitle .pageTitle {
    font-feature-settings: "palt";
    letter-spacing: -0.05em;
    padding-left: 34px;
  }
  .pageHeader.courseTitle .pageTitle:before {
    width: 20px;
    top: 23px;
  }
  .archivePageHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 40px 0;
  }
  .archivePageHeader .inner {
    width: calc(100% - 60px);
  }
  .archivePageHeader .category {
    height: 20px;
    line-height: 18px;
    margin-right: 10px;
  }
  .archivePageHeader .date {
    font-size: 16px;
  }
  .archivePageHeader .pageTitle {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  .archivePageHeader.blog .inner {
    padding-bottom: 30px;
  }
  .commonPageInner {
    width: calc(100% - 60px);
    margin-inline: auto;
  }
  main#main {
    padding-bottom: 86px;
  }
  main#main * + p,
  main#course * + p,
  main#archivePage * + p {
    margin-top: 20px;
  }
  main#main .section,
  main#course .section,
  main#archivePage .section {
    padding: 50px 0;
  }
  main#main .section + .section:not([data-background^=bg]),
  main#course .section + .section:not([data-background^=bg]),
  main#archivePage .section + .section:not([data-background^=bg]) {
    padding-top: 26px;
  }
  main#main .section[data-background=bgGrad],
  main#course .section[data-background=bgGrad],
  main#archivePage .section[data-background=bgGrad] {
    padding-block: 45px;
  }
  main#main .section[data-background=bgGrad] + .section:not([data-background^=bg]),
  main#course .section[data-background=bgGrad] + .section:not([data-background^=bg]),
  main#archivePage .section[data-background=bgGrad] + .section:not([data-background^=bg]) {
    padding-top: 60px;
  }
  main#main .section .inner,
  main#course .section .inner,
  main#archivePage .section .inner {
    width: calc(100% - 60px);
    margin-inline: auto;
  }
  main#main .commonInner,
  main#course .commonInner,
  main#archivePage .commonInner {
    width: calc(100% - 60px);
    margin-inline: auto;
  }
  main#main * + [class^=listCol],
  main#course * + [class^=listCol],
  main#archivePage * + [class^=listCol] {
    margin-top: 40px;
  }
  main#main [class^=listCol],
  main#course [class^=listCol],
  main#archivePage [class^=listCol] {
    width: 100%;
  }
  main#main [class^=listCol] > .listItem + .listItem,
  main#course [class^=listCol] > .listItem + .listItem,
  main#archivePage [class^=listCol] > .listItem + .listItem {
    margin-top: 20px;
  }
  main#main .listCol2.columnSP,
  main#course .listCol2.columnSP,
  main#archivePage .listCol2.columnSP {
    display: flex;
  }
  main#main .listCol2.columnSP > .listItem,
  main#course .listCol2.columnSP > .listItem,
  main#archivePage .listCol2.columnSP > .listItem {
    width: calc(50% - 10px);
  }
  main#main .listCol2.instoructor > .listItem.img,
  main#course .listCol2.instoructor > .listItem.img,
  main#archivePage .listCol2.instoructor > .listItem.img {
    width: 50%;
    padding-right: 30px;
  }
  main#main .listCol2.instoructor > .listItem.text,
  main#course .listCol2.instoructor > .listItem.text,
  main#archivePage .listCol2.instoructor > .listItem.text {
    width: 50%;
  }
  main#main * + .itemCenter,
  main#course * + .itemCenter,
  main#archivePage * + .itemCenter {
    margin-top: 40px;
  }
  main#main * + .bdBox,
  main#course * + .bdBox,
  main#archivePage * + .bdBox {
    margin-top: 40px;
  }
  main#main .bdBox,
  main#course .bdBox,
  main#archivePage .bdBox {
    padding: 20px 25px;
  }
  main#main * + .btnWrap,
  main#course * + .btnWrap,
  main#archivePage * + .btnWrap {
    margin-top: 40px;
  }
  main#main * + .btnWrap.center,
  main#course * + .btnWrap.center,
  main#archivePage * + .btnWrap.center {
    margin-top: 55px;
  }
  main#main .btnWrap a:before,
  main#course .btnWrap a:before,
  main#archivePage .btnWrap a:before {
    width: 20px;
    height: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  #archiveContet {
    padding-block: 40px 100px;
  }
  #archiveContet.blog {
    padding-top: 40px;
  }
  #archiveContet * + h2.title {
    margin-top: 60px;
  }
  #archiveContet h2.title {
    font-size: 20px;
    padding-left: 38px;
  }
  #archiveContet h2.title:before {
    width: 24px;
    top: 14px;
  }
  #coursePage * + h2.title {
    margin-top: 60px;
  }
  #coursePage h2.title.pro:before, #coursePage h2.title.pro:after {
    background-color: #AE6741;
  }
  #coursePage .accordionWrap {
    margin-top: 70px;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 50px;
  }
  #coursePage .accordionWrap dl.accordionBtn {
    width: calc(50% - 30px);
    min-width: 470px;
    margin-inline: auto;
  }
  #coursePage .accordionWrap dl.accordionBtn dt {
    padding-inline: min(4.5vw, 64px);
  }
  #coursePage .accordionWrap dl.accordionBtn dt:before {
    right: min(1.4vw, 20px);
  }
  #coursePage .accordionWrap dl.accordionBtn dd .text {
    margin-top: 30px;
  }
  #coursePage .accordionWrap dl.accordionBtn.professional dt {
    justify-content: flex-start;
    padding-left: 20px;
  }
  #coursePage .accordionWrap dl.accordionBtn.professional dt .tag {
    width: 160px;
    line-height: 29px;
  }
  #coursePage .accordionWrap dl.accordionBtn.professional dt .dt {
    width: calc(100% - 160px);
    padding-left: 16px;
  }
  #coursePage .proDiscription {
    margin-top: 30px;
    line-height: 1.8;
  }
  #coursePage * + .grayBox {
    margin-top: 70px;
  }
  #coursePage .grayBox {
    padding: 40px;
    border-radius: 10px;
  }
  #coursePage .instructorList {
    gap: 40px;
    margin-top: 50px;
  }
  #coursePage .instructorList li {
    width: calc((100% - 120px) / 4);
  }
  #coursePage .instructorList li picture::before {
    width: 20px;
    height: 20px;
  }
  #coursePage .instructorList li picture:after {
    right: 15px;
    bottom: 15px;
    width: 26px;
    height: 26px;
  }
  #coursePage .instructorList li .textWrap span {
    font-size: 14px;
  }
  #coursePage .instructorList li button {
    transition: 0.2s ease;
  }
  #coursePage .instructorList li button:hover {
    opacity: 0.5;
  }
  #coursePage .instructorList #modal01,
  #coursePage .instructorList #modal02,
  #coursePage .instructorList #modal03,
  #coursePage .instructorList #modal04 {
    top: 55%;
    width: 70%;
    height: 50vh;
    max-width: 800px;
  }
  #coursePage .instructorList #modal01 .modal_inner .modal_contents,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents {
    padding-block: 40px 80px;
    padding-inline: 40px;
  }
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal01 .modal_inner .modal_contents .modal_dismiss04,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal02 .modal_inner .modal_contents .modal_dismiss04,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal03 .modal_inner .modal_contents .modal_dismiss04,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss01,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss02,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss03,
  #coursePage .instructorList #modal04 .modal_inner .modal_contents .modal_dismiss04 {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
  #coursePage .corpoPrice {
    margin-top: 45px;
  }
  #coursePage .corpoPrice h3.title {
    padding-block: 8px;
    min-width: 300px;
  }
  #coursePage .corpoPrice .price {
    line-height: 40px;
    margin-top: 20px;
  }
  #coursePage .corpoPrice .price .size18 {
    font-size: 20px;
  }
  #coursePage .corpoPrice .price .size36 {
    font-size: 40px;
    padding-left: 8px;
  }
  #coursePage .corpoPrice .price .size16 {
    font-size: 18px;
  }
  #news a.page {
    transition: 0.2s ease;
  }
  #news a.page:hover {
    opacity: 0.7;
  }
  #news .previouspostslink,
  #news .nextpostslink {
    transition: 0.2s ease;
  }
  #news .previouspostslink:hover,
  #news .nextpostslink:hover {
    opacity: 0.7;
  }
  .course .list {
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 50px;
  }
  .course .list li {
    width: calc((100% - 30px) / 2) !important;
  }
  .course .list li a .imgWrap {
    width: 100%;
    height: 130px !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover !important;
    transition: all 0.3s ease;
    border-radius: 10px;
  }
  .course .btn2col {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  .course .btn2col a.btnBlack {
    margin-inline: 0 !important;
  }
  .prevNextLink {
    gap: 60px;
    margin-top: 100px;
    font-size: 18px;
  }
  .prevNextLink .prev {
    padding-left: 32px;
  }
  .prevNextLink .prev a:before {
    width: 22px;
    height: 22px;
  }
  .prevNextLink .next {
    padding-right: 32px;
  }
  .prevNextLink .next a:before {
    width: 22px;
    height: 22px;
  }
  .breadcrumbArea {
    width: calc(100% - 80px);
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
  }
  .breadcrumbArea .breadcrumb {
    margin-top: 15px;
  }
  .breadcrumbArea .breadcrumb > li {
    display: inline;
    font-size: 13px;
  }
  .breadcrumbArea .breadcrumb > li:not(:last-child) {
    margin-right: 12px;
  }
  .breadcrumbArea .breadcrumb > li + li {
    padding-left: 22px;
  }
  .breadcrumbArea .breadcrumb > li + li:before {
    width: 6px;
    height: 6px;
    left: 0px;
    top: 7px;
  }
  .breadcrumbArea .breadcrumb > li a {
    transition: 0.2s opacity;
  }
  .breadcrumbArea .breadcrumb > li a:hover {
    opacity: 0.5;
  }
  .breadcrumbArea + .section {
    padding-top: 40px;
  }
  .pageHeader {
    padding-block: 30px 50px;
  }
  .pageHeader .inner {
    width: calc(100% - 100px);
    max-width: 1000px;
  }
  .pageHeader .pageTitleSub {
    font-size: 20px;
  }
  .pageHeader .pageTitle {
    margin-top: 10px;
    font-size: 40px;
  }
  .pageHeader .pageTitle.thanks {
    font-size: 32px;
  }
  .pageHeader .pageImg {
    height: 250px;
  }
  .pageHeader.courseTitle .pageTitle {
    padding-left: 38px;
  }
  .pageHeader.courseTitle .pageTitle:before {
    width: 24px;
    top: 23px;
  }
  .archivePageHeader {
    padding-block: 100px 0;
  }
  .archivePageHeader .inner {
    width: calc(100% - 100px);
    max-width: 1000px;
  }
  .archivePageHeader .category {
    height: 23px;
    line-height: 21px;
    margin-right: 20px;
  }
  .archivePageHeader .date {
    font-size: 18px;
  }
  .archivePageHeader .pageTitle {
    margin-top: 12px;
    font-size: 20px;
    font-weight: 700;
  }
  .archivePageHeader.blog .inner {
    padding-bottom: 40px;
  }
  .commonPageInner {
    max-width: 1000px;
    width: calc(100% - 120px);
    margin: 0 auto;
  }
  main#main {
    padding-bottom: 100px;
  }
  main#main * + p,
  main#course * + p,
  main#archivePage * + p {
    margin-top: 25px;
  }
  main#main a,
  main#course a,
  main#archivePage a {
    transition: 0.2s ease;
  }
  main#main a:hover,
  main#course a:hover,
  main#archivePage a:hover {
    text-decoration: none;
  }
  main#main .section,
  main#course .section,
  main#archivePage .section {
    padding: 100px 0 80px;
  }
  main#main .section + .section:not([data-background^=bg]),
  main#course .section + .section:not([data-background^=bg]),
  main#archivePage .section + .section:not([data-background^=bg]) {
    padding-top: 30px;
  }
  main#main .section[data-background=bgGrad],
  main#course .section[data-background=bgGrad],
  main#archivePage .section[data-background=bgGrad] {
    padding-block: 50px;
  }
  main#main .section[data-background=bgGrad] + .section:not([data-background^=bg]),
  main#course .section[data-background=bgGrad] + .section:not([data-background^=bg]),
  main#archivePage .section[data-background=bgGrad] + .section:not([data-background^=bg]) {
    padding-top: 100px;
  }
  main#main .section .inner,
  main#course .section .inner,
  main#archivePage .section .inner {
    max-width: 1000px;
    width: calc(100% - 120px);
    margin: 0 auto;
  }
  main#main .commonInner,
  main#course .commonInner,
  main#archivePage .commonInner {
    max-width: 1000px;
    width: calc(100% - 120px);
    margin: 0 auto;
  }
  main#main * + [class^=listCol],
  main#course * + [class^=listCol],
  main#archivePage * + [class^=listCol] {
    margin-top: 50px;
  }
  main#main [class^=listCol],
  main#course [class^=listCol],
  main#archivePage [class^=listCol] {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  main#main .listCol2 > .listItem,
  main#course .listCol2 > .listItem,
  main#archivePage .listCol2 > .listItem {
    width: calc(50% - 25px);
  }
  main#main .listCol2 > .listItem:nth-child(odd),
  main#course .listCol2 > .listItem:nth-child(odd),
  main#archivePage .listCol2 > .listItem:nth-child(odd) {
    margin-right: 50px;
  }
  main#main .listCol2 > .listItem:nth-child(n+3),
  main#course .listCol2 > .listItem:nth-child(n+3),
  main#archivePage .listCol2 > .listItem:nth-child(n+3) {
    margin-top: 50px;
  }
  main#main .listCol2.instoructor > .listItem.img,
  main#course .listCol2.instoructor > .listItem.img,
  main#archivePage .listCol2.instoructor > .listItem.img {
    width: calc(35% - 25px);
  }
  main#main .listCol2.instoructor > .listItem.text,
  main#course .listCol2.instoructor > .listItem.text,
  main#archivePage .listCol2.instoructor > .listItem.text {
    width: calc(65% - 25px);
  }
  main#main .listCol3 .listItem,
  main#course .listCol3 .listItem,
  main#archivePage .listCol3 .listItem {
    width: calc(33.3% - 34px);
  }
  main#main .listCol3 .listItem:not(:nth-child(3n)),
  main#course .listCol3 .listItem:not(:nth-child(3n)),
  main#archivePage .listCol3 .listItem:not(:nth-child(3n)) {
    margin-right: 50px;
  }
  main#main .listCol3 .listItem:nth-child(n+4),
  main#course .listCol3 .listItem:nth-child(n+4),
  main#archivePage .listCol3 .listItem:nth-child(n+4) {
    margin-top: 50px;
  }
  main#main .listCol4 .listItem,
  main#course .listCol4 .listItem,
  main#archivePage .listCol4 .listItem {
    width: calc(25% - 26px);
  }
  main#main .listCol4 .listItem:not(:nth-child(4n)),
  main#course .listCol4 .listItem:not(:nth-child(4n)),
  main#archivePage .listCol4 .listItem:not(:nth-child(4n)) {
    margin-right: 30px;
  }
  main#main .listCol4 .listItem:nth-child(n+5),
  main#course .listCol4 .listItem:nth-child(n+5),
  main#archivePage .listCol4 .listItem:nth-child(n+5) {
    margin-top: 30px;
  }
  main#main * + .itemCenter,
  main#course * + .itemCenter,
  main#archivePage * + .itemCenter {
    margin-top: 50px;
  }
  main#main * + .bdBox,
  main#course * + .bdBox,
  main#archivePage * + .bdBox {
    margin-top: 50px;
  }
  main#main .bdBox,
  main#course .bdBox,
  main#archivePage .bdBox {
    padding: 44px 53px;
  }
  main#main * + .btnWrap,
  main#course * + .btnWrap,
  main#archivePage * + .btnWrap {
    margin-top: 50px;
  }
  main#main * + .btnWrap.center,
  main#course * + .btnWrap.center,
  main#archivePage * + .btnWrap.center {
    margin-top: 70px;
  }
  main#main .btnWrap,
  main#course .btnWrap,
  main#archivePage .btnWrap {
    margin-inline: auto;
    max-width: 90%;
  }
  main#main .btnWrap.center,
  main#course .btnWrap.center,
  main#archivePage .btnWrap.center {
    max-width: 370px;
  }
  main#main .btnWrap.apply,
  main#course .btnWrap.apply,
  main#archivePage .btnWrap.apply {
    max-width: 500px;
  }
  main#main .btnWrap a,
  main#course .btnWrap a,
  main#archivePage .btnWrap a {
    transition: all 0.3s ease;
  }
  main#main .btnWrap a:before,
  main#course .btnWrap a:before,
  main#archivePage .btnWrap a:before {
    width: 24px;
    height: 24px;
  }
  main#main .btnWrap a:hover,
  main#course .btnWrap a:hover,
  main#archivePage .btnWrap a:hover {
    color: #000;
    background: #fff;
  }
  main#main .btnWrap a:hover:before,
  main#course .btnWrap a:hover:before,
  main#archivePage .btnWrap a:hover:before {
    background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  }
  main#main .btnWrap a.gradBg:hover,
  main#course .btnWrap a.gradBg:hover,
  main#archivePage .btnWrap a.gradBg:hover {
    background: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
    opacity: 0.4;
  }
  main#main .btnWrap a.gradBg:hover:before,
  main#course .btnWrap a.gradBg:hover:before,
  main#archivePage .btnWrap a.gradBg:hover:before {
    background: url(../images/common/arrow_grad.svg) no-repeat 0 0/contain;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  #coursePage h2.title.pro:before, #coursePage h2.title.pro:after {
    background-color: #AE6741;
  }
}