.bold {
  @if $type =="" {
    font-weight: 700;
  }
}

#archiveContet {
  @if $type =="" {}

  @if $type ==sp {
    padding-block: 40px 86px;
  }

  @if $type ==pc {
    padding-block: 40px 100px;
  }

  &.blog {
    @if $type =="" {}

    @if $type ==sp {
      padding-top: 30px;
    }

    @if $type ==pc {
      padding-top: 40px;
    }
  }


  //タイトル
  *+h2.title {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 40px;
    }

    @if $type ==pc {
      margin-top: 60px;
    }
  }

  h2.title {
    @if $type =="" {
      font-weight: 700;
      line-height: 1.5;
      position: relative;
    }

    @if $type ==sp {
      font-size: 16px;
      padding-left: 34px;
    }

    @if $type ==pc {
      font-size: 20px;
      padding-left: 38px;
    }

    &:before {
      @if $type =="" {
        content: "";
        position: absolute;
        background-color: #000;
        left: 0;
        height: 2px;
      }

      @if $type ==sp {
        width: 20px;
        top: 11px;
      }

      @if $type ==pc {
        width: 24px;
        top: 14px;
      }
    }
  }
}


#coursePage {
  @if $type =="" {}

  //タイトル

  h1,
  h2,
  h3,
  h4,
  h5 {
    @if $type =="" {
      @include fontJa;
      font-weight: 700;
    }
  }


  *+h2.title {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 40px;
    }

    @if $type ==pc {
      margin-top: 60px;
    }
  }

  h2.title {
    @if $type =="" {
      font-weight: 700;
      line-height: 1.5;
      position: relative;
      font-size: 18px;
      padding-block: 10px;
      text-align: center;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    &:before,
    &:after {
      @if $type =="" {
        content: "";
        position: absolute;
        background-color: #000;
        left: 0;
        width: 100%;
        height: 1px;
      }

      @if $type ==sp {}

      @if $type ==pc {}
    }

    &:before {
      @if $type =="" {
        top: 0;
      }
    }

    &:after {
      @if $type =="" {
        bottom: 0;
      }
    }

    &.pro {
      @if $type =="" {
        color: #AE6741;
      }

      @if $type ==sp {}

      @if $type ==pc {}

      &:before,
      &:after {
        background-color: #AE6741;
      }
    }
  }

  .accordionWrap {
    @if $type =="" {
      display: flex;
    }

    @if $type ==sp {
      margin-top: 50px;
      flex-direction: column;
      row-gap: 20px;
    }

    @if $type ==pc {
      margin-top: 70px;
      flex-wrap: wrap;
      column-gap: 60px;
      row-gap: 50px;
    }

    dl.accordionBtn {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {
        width: calc(50% - 30px);
        min-width: 470px;
        margin-inline: auto;
      }

      dt {
        @if $type =="" {
          text-align: left;
          position: relative;
          cursor: pointer;
          color: #fff;
          font-weight: 700;
          background-color: #000;
          border-radius: 5px;
          text-align: center;
          font-size: 16px;
          line-height: 1.2;
          min-height: 70px;
          padding-block: 0.8em;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 0em;
        }

        @if $type ==sp {
          padding-inline: 50px;
        }

        @if $type ==pc {
          // padding-inline: 64px;
          padding-inline: min(4.5vw, 64px);
        }

        &:before {
          @if $type =="" {
            content: "";
            position: absolute;
            background: url(../images/common/arrow_white.svg) no-repeat 0 0 / contain;
            top: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            width: 20px;
            height: 20px;
          }

          @if $type ==sp {
            right: 8px;
          }

          @if $type ==pc {
            // right: 20px;
            right: min(1.4vw, 20px);
          }

        }

        &.open {
          @if $type =="" {
            border-radius: 5px 5px 0 0;
          }

          &:before {
            @if $type =="" {
              transform: translate(-50%, -50%) rotate(-90deg);
            }
          }
        }

      }

      dd {
        @if $type =="" {
          display: none;
          position: relative;
          background: #F8F8F8;
          color: #000;
          border-radius: 0px 0px 5px 5px;
          padding: 40px;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        .img {
          @if $type =="" {}

          @if $type ==sp {}

          @if $type ==pc {}

          img {
            @if $type =="" {
              border-radius: 10px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }
        }

        .text {
          @if $type =="" {}

          @if $type ==sp {
            margin-top: 20px;
          }

          @if $type ==pc {
            margin-top: 30px;
          }
        }
      }

      &.professional {
        dt {
          @if $type =="" {
            text-align: left;
          }

          @if $type ==sp {
            padding-left: 12px;
          }

          @if $type ==pc {
            justify-content: flex-start;
            padding-left: 20px;
          }

          .tag {
            @if $type =="" {
              color: #000;
              background-color: #fff;
              border-radius: 5px;
              font-size: 12px;
              text-align: center;
            }

            @if $type ==sp {
              width: 83px;
              line-height: 1.33;
            }

            @if $type ==pc {
              width: 160px;
              line-height: 29px;
            }
          }

          .dt {
            @if $type =="" {
              margin-top: 0px;
            }

            @if $type ==sp {
              width: calc(100% - 83px);
              padding-left: 16px;
            }

            @if $type ==pc {
              width: calc(100% - 160px);
              padding-left: 16px;
            }
          }
        }
      }
    }
  }

  .proDiscription {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 20px;
      line-height: 2;
    }

    @if $type ==pc {
      margin-top: 30px;
      line-height: 1.8;
    }

    *+p {
      @if $type =="" {
        margin-top: 0px;
      }

      @if $type ==sp {}

      @if $type ==pc {}
    }
  }

  *+.grayBox {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 50px;
    }

    @if $type ==pc {
      margin-top: 70px;
    }
  }

  .grayBox {
    @if $type =="" {
      position: relative;
      background: #F8F8F8;
      color: #000;
    }

    @if $type ==sp {
      padding: 29px;
      border-radius: 5px;
    }

    @if $type ==pc {
      padding: 40px;
      border-radius: 10px;
    }

    .img {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {}

      img {
        @if $type =="" {
          border-radius: 10px;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }
    }

    .text {
      @if $type =="" {}

      @if $type ==sp {
        margin-top: 20px;
      }

      @if $type ==pc {}

      p.title {
        @if $type =="" {
          font-size: 20px;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }
    }
  }

  .instructorList {

    @if $type =="" {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    @if $type ==sp {
      gap: 15px;
      row-gap: 40px;
      margin-top: 40px;
    }

    @if $type ==pc {
      gap: 40px;
      margin-top: 50px;
    }

    li {
      @if $type =="" {}

      @if $type ==sp {
        width: calc((100% - 15px)/2);
      }

      @if $type ==pc {
        width: calc((100% - 120px)/4);
      }

      button {
        @if $type =="" {
          border: 0px;
          background-color: #fff;
          cursor: pointer;
        }
      }

      picture {
        @if $type =="" {
          width: 100%;
          position: relative;
          display: block;
          aspect-ratio: 1/1;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        &::before {
          @if $type =="" {
            content: '';
            background: linear-gradient(to right bottom, #000 50%, transparent 50%);
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px 0 0 0;
          }

          @if $type ==sp {
            width: 15px;
            height: 15px;
          }

          @if $type ==pc {
            width: 20px;
            height: 20px;
          }
        }

        &:after {
          @if $type =="" {
            content: "";
            position: absolute;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
          }

          @if $type ==sp {
            right: 10px;
            bottom: 10px;
            width: 20px;
            height: 20px;
          }

          @if $type ==pc {
            right: 15px;
            bottom: 15px;
            width: 26px;
            height: 26px;
          }
        }

        &.white {
          &:after {
            @if $type =="" {
              background: url(../images/common/icon_open_w.svg) no-repeat 0 0 / contain;
            }
          }
        }

        &.black {
          &:after {
            @if $type =="" {
              background: url(../images/common/icon_open_b.svg) no-repeat 0 0 / contain;
            }
          }
        }

        img {
          @if $type =="" {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
      }

      .textWrap {
        @if $type =="" {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          row-gap: 5px;
          padding-inline: 5px;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        span {
          @if $type =="" {
            text-align: center;
            line-height: 1.3;
          }

          @if $type ==sp {
            font-size: 12px;
          }

          @if $type ==pc {
            font-size: 14px;
          }

          &.name {
            @if $type =="" {
              font-size: 20px;
              font-weight: 700;
              line-height: 30px;
            }

            .small {
              @if $type =="" {
                font-size: 16px;
                line-height: 30px;
                margin-left: 4px;
              }

              @if $type ==sp {}

              @if $type ==pc {}
            }
          }
        }
      }

      button {
        @if $type ==pc {
          transition: .2s ease;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    #modal01,
    #modal02,
    #modal03,
    #modal04 {
      @if $type =="" {
        position: fixed;
        left: 50%;
        display: none;
        transition: all 0.3s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: 11;

        &.isShow {
          display: block;
        }
      }

      @if $type ==sp {
        top: 50%;
        width: 100%;
        max-width: 440px;
        // min-height: 462px;
      }

      @if $type ==pc {
        top: 55%;
        width: 70%;
        height: 50vh;
        // min-width: 400px;
        // min-height: 485px;
        max-width: 800px;
      }

      .modal_inner {
        @if $type =="" {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }


        @if $type ==sp {
          padding-inline: 16px;
        }

        .modal_contents {
          @if $type =="" {
            background-color: #fff;
            border-radius: 10px;
            position: relative;
            width: 100%;
            height: 100%;
          }

          &::before {
            @if $type =="" {
              content: '';
              background: linear-gradient(to right bottom, #000 50%, transparent 50%);
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 10px 0 0 0;
              width: 24px;
              height: 24px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

          .textWrap {
            span {
              @if $type =="" {
                text-align: left;
              }

              @if $type ==sp {}

              @if $type ==pc {}
            }
          }

          .messages {
            @if $type =="" {
              letter-spacing: 0em;
              margin-top: 40px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

          @if $type ==sp {
            padding-block: 30px;
            padding-inline: 30px;
          }

          @if $type ==pc {
            padding-block: 40px 80px;
            padding-inline: 40px;
          }

          .modal_dismiss01,
          .modal_dismiss02,
          .modal_dismiss03,
          .modal_dismiss04 {
            @if $type =="" {
              content: "";
              margin-top: 0;
              position: absolute;
              display: block;
              cursor: pointer;
              background: url(../images/common/icon_dismiss.svg) no-repeat 0 0 / contain;
            }

            @if $type ==sp {
              top: 20px;
              right: 20px;
              width: 36px;
              height: 36px;
            }

            @if $type ==pc {
              top: 20px;
              right: 20px;
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }

    .modal_backgronud01,
    .modal_backgronud02,
    .modal_backgronud03,
    .modal_backgronud04 {
      @if $type =="" {
        display: none;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transition: all 0.3s ease-in-out;
        background: rgba($color: #7A7A7A, $alpha: 0.5);
        z-index: 10;
      }

      &.isShow {
        @if $type =="" {
          display: block;
        }
      }
    }
  }

  .corpoPrice {
    @if $type =="" {
      text-align: center;
    }

    @if $type ==sp {
      margin-top: 30px;
    }

    @if $type ==pc {
      margin-top: 45px;
    }

    h3.title {
      @if $type =="" {
        color: #fff;
        background-color: #000;
        display: inline-block;
        font-size: 18px;
      }

      @if $type ==sp {
        padding-block: 5px;
        min-width: 200px;
      }

      @if $type ==pc {
        padding-block: 8px;
        min-width: 300px;
      }
    }

    .price {
      @if $type =="" {
        letter-spacing: 0em;
        font-weight: 700;
      }

      @if $type ==sp {
        line-height: 36px;
        margin-top: 10px;
      }

      @if $type ==pc {
        line-height: 40px;
        margin-top: 20px;
      }

      .size18 {
        @if $type =="" {}

        @if $type ==sp {
          font-size: 18px;
        }

        @if $type ==pc {
          font-size: 20px;
        }
      }

      .size36 {
        @if $type =="" {
          @include fontEn;
        }

        @if $type ==sp {
          font-size: 36px;
          padding-left: 5px;
        }

        @if $type ==pc {
          font-size: 40px;
          padding-left: 8px;
        }
      }

      .size16 {
        @if $type =="" {}

        @if $type ==sp {
          font-size: 16px;
        }

        @if $type ==pc {
          font-size: 18px;
        }
      }

      .dBlock {
        @if $type =="" {
          font-weight: 400;
          display: block;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }
    }
  }
}



#news {

  .wp-pagenavi {
    @if $type =="" {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      gap: 10px;
    }

    @if $type ==sp {}

    @if $type ==pc {}
  }


  a.page,
  .current {
    @if $type =="" {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      @include fontEn;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    @if $type ==sp {}

    @if $type ==pc {}
  }

  a.page {
    @if $type =="" {
      background-color: #f5f5f5;
    }

    @if $type ==sp {}

    @if $type ==pc {
      transition: .2s ease;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .current {
    @if $type =="" {
      background-color: #000;
      color: #fff;
    }

    @if $type ==sp {}

    @if $type ==pc {}
  }

  .previouspostslink,
  .nextpostslink {
    @if $type =="" {
      display: block;
      font-size: 0px;
      width: 20px;
      height: 20px;
      position: relative;
    }

    @if $type ==pc {
      transition: .2s ease;

      &:hover {
        opacity: 0.7;
      }
    }

    &:before {
      @if $type =="" {
        content: "";
        position: absolute;
        background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
        width: 20px;
        height: 20px;
        display: block;
      }

      @if $type ==sp {}

      @if $type ==pc {}
    }
  }

  .previouspostslink {
    &:before {
      @if $type =="" {
        transform: rotate(-180deg);
      }
    }
  }
}

.course {
  .list {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 50px;
    }

    li {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {
        width: calc((100% - 30px) / 2) !important;
      }

      a {
        .imgWrap {
          @if $type =="" {}

          @if $type ==sp {}

          @if $type ==pc {
            width: 100%;
            height: 130px !important;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover !important;
            transition: all 0.3s ease;
            border-radius: 10px;
          }

          &.vocal {
            @if $type =="" {
              background-image: url(../images/home/course01_thum_sp.png) !important;
              background-size: cover;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

          &.guiter {
            @if $type =="" {
              background-image: url(../images/home/course02_thum_sp.png) !important;
              background-size: cover;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

          &.kids {
            @if $type =="" {
              background-image: url(../images/home/course03_thum_sp.png) !important;
              background-size: cover;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

          &.lgbtq {
            @if $type =="" {
              background-image: url(../images/home/course04_thum_sp.png) !important;
              background-size: cover;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }
        }
      }
    }
  }

  .btn2col {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
      display: flex;
      justify-content: center;
      gap: 30px;
    }

    a.btnBlack {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {
        margin-inline: 0 !important;
      }

      +.btnBlack {
        @if $type =="" {}

        @if $type ==sp {
          margin-top: 30px !important;
        }

        @if $type ==pc {}
      }
    }
  }
}

.prevNextLink {
  @if $type =="" {
    display: flex;
    justify-content: center;
  }

  @if $type ==sp {
    gap: 40px;
    margin-top: 40px;
  }

  @if $type ==pc {
    gap: 60px;
    margin-top: 100px;
    font-size: 18px;
  }

  .prev {
    @if $type =="" {
      position: relative;
      padding-left: 40px;
    }

    @if $type ==sp {
      padding-left: 25px;
    }

    @if $type ==pc {
      padding-left: 32px;
    }

    a {
      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
          display: block;
          transform: translate(0, -50%) rotate(-180deg);
          left: 0;
          top: 50%;
        }

        @if $type ==sp {
          width: 20px;
          height: 20px;
        }

        @if $type ==pc {
          width: 22px;
          height: 22px;
        }
      }
    }


  }

  .next {
    @if $type =="" {
      position: relative;
      padding-right: 40px;
    }

    @if $type ==sp {
      padding-right: 25px;
    }

    @if $type ==pc {
      padding-right: 32px;
    }

    a {
      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
          display: block;
          transform: translate(0, -50%);
          right: 0;
          top: 50%;
        }

        @if $type ==sp {
          width: 20px;
          height: 20px;
        }

        @if $type ==pc {
          width: 22px;
          height: 22px;
        }
      }
    }


  }
}

// import //

// use Slick 
//@import "_slick";
//@import "_slick-theme";