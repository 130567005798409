.en {
  @if $type =="" {
    font-family: "Inter", sans-serif !important;
  }

  @if $type ==sp {}

  @if $type ==pc {}
}

.breadcrumbArea {
  @if $type =="" {}

  @if $type ==sp {
    width: calc(100% - 50px);
    margin: 0 auto;
  }

  @if $type ==pc {
    width: calc(100% - 80px);
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
  }

  .breadcrumb {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 15px;
    }

    @if $type ==pc {
      margin-top: 15px;
    }

    >li {
      @if $type =="" {
        position: relative;
      }

      @if $type ==sp {
        display: inline-block;
        margin: 1px 0;
        font-size: 12px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      @if $type ==pc {
        display: inline;
        font-size: 13px;

        &:not(:last-child) {
          margin-right: 12px;
        }
      }

      +li {
        @if $type =="" {}

        @if $type ==sp {
          padding-left: 16px;
        }

        @if $type ==pc {
          padding-left: 22px;
        }

        &:before {
          @if $type =="" {
            content: "";
            display: block;
            position: absolute;
            margin: auto;
            // background: #000;
            border-top: 1px solid $text_gray;
            border-right: 1px solid $text_gray;
            transform: rotate(45deg);
            box-sizing: border-box;
          }

          @if $type ==sp {
            width: 5px;
            height: 5px;
            left: 0px;
            top: 8px;
          }

          @if $type ==pc {
            width: 6px;
            height: 6px;
            left: 0px;
            top: 7px;
          }
        }
      }

      a {
        @if $type =="" {
          color: $text_gray !important;
          text-decoration: none !important;
          font-weight: 500 !important;
        }

        @if $type ==sp {
          display: inline;
        }

        @if $type ==pc {
          transition: .2s opacity;

          &:hover {
            opacity: .5;
          }
        }
      }

      >span {
        @if $type =="" {
          color: $text_gray;
        }

        @if $type ==sp {}

        @if $type ==pc {
          // font-weight: bold;
        }
      }
    }
  }

  +.section {
    @if $type =="" {}

    @if $type ==sp {
      padding-top: 40px;
    }

    @if $type ==pc {
      padding-top: 40px;
    }
  }
}

.pageHeader {
  @if $type =="" {}

  @if $type ==sp {
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 155px;
    padding-block: 30px 40px;
  }

  @if $type ==pc {
    // min-height: 176px;
    padding-block: 30px 50px;
  }

  .inner {
    @if $type =="" {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin: 0 auto;
      position: relative;
    }

    @if $type ==sp {
      width: calc(100% - 60px);
      // min-height: 155px;
    }

    @if $type ==pc {
      width: calc(100% - 100px);
      max-width: 1000px;
      // min-height: 176px;
    }
  }

  .pageTitleSub {
    @if $type =="" {
      @include fontEn;
      // text-transform: uppercase;
      text-transform: capitalize;
      color: $text_gray;
      font-weight: 600;
      letter-spacing: 0.1em;
    }

    @if $type ==sp {
      font-size: 16px;
    }

    @if $type ==pc {
      font-size: 20px;
    }
  }

  .pageTitle {
    @if $type =="" {
      @include fontJa;
      font-weight: 700;
      letter-spacing: 0em;
      line-height: 1.4;
    }

    @if $type ==sp {
      font-size: 40px;
    }

    @if $type ==pc {
      margin-top: 10px;
      font-size: 40px;
    }

    &.thanks {
      @if $type =="" {
        letter-spacing: 0.02em;
        text-align: center;
      }

      @if $type ==sp {
        font-size: 20px;
      }

      @if $type ==pc {
        font-size: 32px;
      }
    }
  }

  .pageImg {
    @if $type =="" {
      margin-top: 20px;
      border-radius: 10px;
      overflow: hidden;
    }

    @if $type ==sp {
      height: 200px;
    }

    @if $type ==pc {
      height: 250px;
    }

    img {
      @if $type =="" {
        object-fit: cover;
        object-position: 50% 50% !important;
        width: 100% !important;
        height: 100% !important;
      }

      @if $type ==sp {}

      @if $type ==pc {}
    }
  }

  &.courseTitle {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    .pageTitle {
      @if $type =="" {
        position: relative;
        font-size: 32px;
      }

      @if $type ==sp {
        font-feature-settings: "palt";
        letter-spacing: -0.05em;
        padding-left: 34px;
      }

      @if $type ==pc {
        padding-left: 38px;
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          left: 0;
          height: 2px;
        }

        @if $type ==sp {
          width: 20px;
          top: 23px;
        }

        @if $type ==pc {
          width: 24px;
          top: 23px;
        }
      }

      &.vocal {
        &:before {
          @if $type =="" {
            background: $momo;
          }
        }
      }

      &.guiter {
        &:before {
          @if $type =="" {
            background: $yellow;
          }
        }
      }

      &.kids {
        &:before {
          @if $type =="" {
            background: $green;
          }
        }
      }

      &.corporations {
        &:before {
          @if $type =="" {
            background: #000;
          }
        }
      }
    }
  }
}

.archivePageHeader {
  @if $type =="" {}

  @if $type ==sp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 40px 0;
    // min-height: 155px;
  }

  @if $type ==pc {
    padding-block: 100px 0;
    // min-height: 176px;
  }

  .inner {
    @if $type =="" {
      padding: 0;
      margin: 0 auto;
      position: relative;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }

    @if $type ==sp {
      width: calc(100% - 60px);
      // min-height: 155px;
    }

    @if $type ==pc {
      width: calc(100% - 100px);
      max-width: 1000px;
      // min-height: 176px;
    }
  }

  .category {
    @if $type =="" {
      width: 96px;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      border-radius: 5px;
    }

    @if $type ==sp {
      height: 20px;
      line-height: 18px;
      margin-right: 10px;
    }

    @if $type ==pc {
      height: 23px;
      line-height: 21px;
      margin-right: 20px;
    }

    &.pink {
      @if $type =="" {
        color: $pink;
        border: 1px solid $pink;
      }
    }

    &.yellow {
      @if $type =="" {
        color: $yellow;
        border: 1px solid $yellow;
      }
    }

    &.green {
      @if $type =="" {
        color: $green;
        border: 1px solid $green;
      }
    }
  }

  .date {
    @if $type =="" {
      font-weight: 700;
      @include fontEn;
    }

    @if $type ==sp {
      font-size: 16px;
    }

    @if $type ==pc {
      font-size: 18px;
    }
  }

  .pageTitle {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 400;
    }

    @if $type ==pc {
      margin-top: 12px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &.news {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}
  }

  &.blog {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    .inner {
      @if $type =="" {
        position: relative;
      }

      @if $type ==sp {
        padding-bottom: 30px;
      }

      @if $type ==pc {
        padding-bottom: 40px;
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background-color: #dadada;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }

    }

    .category {
      @if $type =="" {
        display: none;
      }
    }
  }
}



.commonPageInner {
  @if $type =="" {}

  @if $type ==sp {
    width: calc(100% - 60px);
    margin-inline: auto;
  }

  @if $type ==pc {
    @include maxPC;
  }
}

main#main {
  @if $type =="" {}

  @if $type ==sp {
    padding-bottom: 86px;
  }

  @if $type ==pc {
    padding-bottom: 100px;
  }
}

main#main,
main#course,
main#archivePage {
  @if $type =="" {
    flex: inherit;

    img,
    source {
      width: auto;
    }

    picture {
      display: inline;
    }

    h2,
    h3,
    h4,
    h5 {
      @include fontUsu;
      font-weight: 700;
    }

    .archivePageHeader {
      h1 {
        @include fontUsu;
        font-weight: 700;
      }
    }
  }



  *+p {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 20px;
    }

    @if $type ==pc {
      margin-top: 25px;
    }
  }

  p.title {
    @if $type =="" {
      font-weight: 700;
    }
  }

  p.description {
    @if $type =="" {
      letter-spacing: 0em;
    }
  }

  a {
    @if $type =="" {
      color: #000;
      text-decoration: underline;
    }

    @if $type ==sp {}

    @if $type ==pc {
      transition: .2s ease;

      &:hover {
        text-decoration: none;
      }
    }
  }

  >.section:nth-of-type(1) {
    @if $type =="" {
      padding-top: 0 !important;
    }

    @if $type ==sp {}

    @if $type ==pc {}
  }

  .content {
    >.section:nth-of-type(1) {
      @if $type =="" {
        padding-top: 0 !important;
      }

      @if $type ==sp {}

      @if $type ==pc {}
    }
  }

  .section {
    @if $type =="" {}

    @if $type ==sp {
      padding: 50px 0;

      +.section:not([data-background^="bg"]) {
        padding-top: 26px;
      }
    }

    @if $type ==pc {
      padding: 100px 0 80px;

      +.section:not([data-background^="bg"]) {
        padding-top: 30px;
      }
    }


    &[data-background="bgGrad"] {
      @if $type =="" {
        background: $grad;
      }

      @if $type ==sp {
        padding-block: 45px;

        +.section:not([data-background^="bg"]) {
          padding-top: 60px;
        }
      }

      @if $type ==pc {
        padding-block: 50px;

        +.section:not([data-background^="bg"]) {
          padding-top: 100px;
        }
      }
    }

    .inner {
      @if $type =="" {}

      @if $type ==sp {
        width: calc(100% - 60px);
        margin-inline: auto;
      }

      @if $type ==pc {
        @include maxPC;
      }
    }
  }

  .commonInner {
    @if $type =="" {}

    @if $type ==sp {
      width: calc(100% - 60px);
      margin-inline: auto;
    }

    @if $type ==pc {
      @include maxPC;
    }
  }

  //カラム

  *+[class^="listCol"] {
    @if $type ==sp {
      margin-top: 40px;
    }

    @if $type ==pc {
      margin-top: 50px;
    }
  }

  [class^="listCol"] {
    @if $type =="" {}

    @if $type ==sp {
      width: 100%;

      >.listItem+.listItem {
        margin-top: 20px;
      }
    }

    @if $type ==pc {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    img {
      @if $type =="" {
        border-radius: 10px;
      }
    }
  }

  .listCol2 {
    >.listItem {
      @if $type ==pc {
        width: calc(50% - 25px);

        &:nth-child(odd) {
          margin-right: 50px;
        }

        &:nth-child(n + 3) {
          margin-top: 50px;
        }
      }
    }

    &.columnSP {
      @if $type ==sp {
        display: flex;
      }

      >.listItem {
        @if $type ==sp {
          width: calc(50% - 10px);
        }
      }
    }

    &.instoructor {
      >.listItem {

        &.img {
          @if $type =="" {}

          @if $type ==sp {
            width: 50%;
            padding-right: 30px;
          }

          @if $type ==pc {
            width: calc(35% - 25px);
          }

          img {
            @if $type =="" {
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 152/176;
            }
          }
        }

        &.text {
          @if $type =="" {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          @if $type ==sp {
            width: 50%;
          }

          @if $type ==pc {
            width: calc(65% - 25px);
          }

          .instoructorName {
            @if $type =="" {
              font-weight: 700;
              font-size: 20px;
              letter-spacing: 0.02em;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

          .postName {
            @if $type =="" {
              font-size: 14px;
              letter-spacing: 0em;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }
        }
      }
    }
  }

  .listCol3 {
    .listItem {
      @if $type ==pc {
        width: calc(33.3% - 34px);

        &:not(:nth-child(3n)) {
          margin-right: 50px;
        }

        &:nth-child(n + 4) {
          margin-top: 50px;
        }
      }
    }
  }

  .listCol4 {
    .listItem {
      @if $type ==pc {
        width: calc(25% - 26px);

        &:not(:nth-child(4n)) {
          margin-right: 30px;
        }

        &:nth-child(n + 5) {
          margin-top: 30px;
        }
      }
    }
  }

  *+.itemCenter {
    @if $type ==sp {
      margin-top: 40px;
    }

    @if $type ==pc {
      margin-top: 50px;
    }
  }

  .itemCenter {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    * {
      @if $type =="" {
        margin-inline: auto;
        display: block;
      }
    }

    img {
      @if $type =="" {
        border-radius: 10px;
      }
    }
  }

  *+.bdBox {
    @if $type ==sp {
      margin-top: 40px;
    }

    @if $type ==pc {
      margin-top: 50px;
    }
  }

  .bdBox {
    @if $type =="" {
      border: 1px solid #dadada;
      border-radius: 10px;
    }

    @if $type ==sp {
      padding: 20px 25px;
    }

    @if $type ==pc {
      padding: 44px 53px;
    }
  }

  *+.btnWrap {
    @if $type ==sp {
      margin-top: 40px;
    }

    @if $type ==pc {
      margin-top: 50px;
    }

    &.center {
      @if $type ==sp {
        margin-top: 55px;
      }

      @if $type ==pc {
        margin-top: 70px;
      }
    }
  }

  .btnWrap {
    @if $type =="" {
      border-radius: 5px;
    }

    @if $type ==sp {}

    @if $type ==pc {
      margin-inline: auto;
      max-width: 90%;
    }

    &.center {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {
        max-width: 370px;
      }
    }

    &.apply {
      @if $type =="" {
        background-color: #fff;
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
      }

      @if $type ==sp {}

      @if $type ==pc {
        max-width: 500px;
      }
    }

    a {
      @if $type =="" {
        display: block;
        color: #fff;
        font-weight: 700;
        background: #000;
        height: 70px;
        line-height: 70px;
        border-radius: 5px;
        padding-inline: 35px;
        position: relative;
        text-align: center;
        border: 1px solid #000;
        text-decoration: none;
      }

      @if $type ==sp {}

      @if $type ==pc {
        transition: all .3s ease;
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/arrow_white.svg) no-repeat 0 0 /contain;
          top: 50%;
          right: 25px;
          transform: translate(0, -50%);
        }

        @if $type ==sp {
          width: 20px;
          height: 20px;
        }

        @if $type ==pc {
          width: 24px;
          height: 24px;
        }
      }

      &:hover {
        @if $type ==pc {
          color: #000;
          background: #fff;

          &:before {
            background: url(../images/common/arrow_black.svg) no-repeat 0 0 /contain;
          }
        }
      }

      &.gradBg {
        @if $type =="" {
          position: relative;
          font-size: 16px;
          background: $grad;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          border-radius: 5px;
          border: 1px solid #fff;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        &:before {
          @if $type =="" {
            content: "";
            position: absolute;
            background: url(../images/common/arrow_grad.svg) no-repeat 0 0 / contain;
            top: 50%;
            right: 15px;
            transform: translate(0, -50%);
            width: 20px;
            height: 20px;
          }

          @if $type ==sp {}

          @if $type ==pc {}
        }

        &:hover {
          @if $type ==pc {
            background: $grad;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-radius: 5px;
            border: 1px solid #fff;
            opacity: .4;

            &:before {
              background: url(../images/common/arrow_grad.svg) no-repeat 0 0 / contain;
            }
          }
        }
      }
    }
  }
}